@import "../../common/colors";

.topbar {
  align-items: center;
  border-bottom: 5px solid #e0e0e0;
  display: flex;
  height: 100%;
  justify-content: stretch;
  position: relative;
  z-index: 100;

  .topbar_item {
    flex-grow: 0;
    flex-shrink: 0;
    height: auto;
    position: relative;
    vertical-align: middle;

    &.stretch {
      flex-grow: 1;
      flex-shrink: 1;
      overflow: hidden;
    }
  }
}

.hamburger_menu {
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  height: 100%;
  justify-content: center;
  width: 53px;

  .hamburger_menu_icon {
    color: use_color("mid-blue");
    font-size: 24px;
  }
}

.page_title {
  color: use-color("black");
  font-size: 2rem;
  font-weight: bold;
  overflow: hidden;
  padding-left: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.environment {
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  font-weight: bold;
  justify-content: center;
  padding: 0 12px 0 5px;

  >svg {
    color: use-color("mid-blue");
    font-size: 2rem;
  }
}

.login_prompt,
.login_prompt:visited {
  color: use-color("primary");
  display: flex;
  flex-flow: column nowrap;
  text-align: center;
  text-decoration: none;
  width: 77px;

  svg {
    font-size: 24px;
  }
}

.login_menu {
  display: block;
  padding: 4px;
  width: 77px;

  .login_menu_username {
    font-style: italic;
  }

  .login_menu_button {
    align-items: center;
    color: use_color("black");
    display: flex;
    gap: 5px;
    justify-content: left;

    .login_menu_icon {
      color: use_color("mid-blue");
      font-size: 30px;
      margin-right: 5px;
    }
  }

  .name_item {
    text-align: center;
    width: 100%;
  }
}
