@import "../common/colors";

.container {
  align-content: stretch;
  align-items: stretch;
  display: flex;
  flex-flow: column nowrap;
  height: 100vh;
  justify-content: stretch;
  position: relative;
  width: 100vw;

  &.fallback {
    .left_navbar,
    .topbar {
      display: none;
    }
  }

  .topbar {
    background-color: use-color("white");
    flex-grow: 0;
    flex-shrink: 0;
    height: 70px;
    position: relative;
    width: 100%;
  }

  .site_content {
    align-content: flex-start;
    align-items: stretch;
    display: flex;
    flex-flow: row nowrap;
    flex-grow: 1;
    flex-shrink: 1;
    height: calc(100% - 70px);
    justify-content: stretch;
    position: relative;

    .left_navbar {
      background-color: use-color("white");
      flex-grow: 0;
      flex-shrink: 0;
      max-height: 100%;
      overflow-y: auto;
      position: relative;
      width: 75px;
    }

    .page_content {
      background-color: use-color("base-lightest");
      flex-grow: 1;
      flex-shrink: 1;
      max-height: 100%;
      overflow-y: auto;
      position: relative;
    }
  }
}

.kbase-modal {
  border: 1px dashed #f0f;
}
