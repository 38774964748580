@import "../../common/colors";

$border: 1px solid use-color("base-lighter");

.collections-main {
  background-color: use-color("base-lightest");
  border-left: 1px solid use-color("silver");
  min-height: 100%;
}

.collections-main .inner-container {
  margin-left: 0;
  padding-left: 1rem;
}

.collections-list {
  overflow: scroll;
  padding: 1rem;
}

.collections-subtitle {
  font-size: 1.25rem;
  font-weight: 500;
  margin: 0;
  padding: 2rem 1rem 1rem;
}

.collection-card {
  height: 100%;

  /* stylelint-disable-next-line selector-class-pattern */
  > :global(.MuiStack-root) {
    border-radius: 4px;
  }
}

.collection-card-title {
  color: use-color("primary");
  font-size: 1.15rem;
  font-weight: bold;
  margin: 0;
}

.clickable-overview-card {
  border: 1px solid use-color("silver");
  cursor: pointer;
  
  /* stylelint-disable-next-line selector-class-pattern */
  &:global(.MuiPaper-root) {
    transition: 0.25s;
  }

  &:hover {
    background-color: use-color("light-gray");
  }
}

.collection_wrapper {
  background-color: use-color("base-lightest");
  display: flex;
  height: auto;
  height: 100%;
  width: 100%;
}

.collection_wrapper .collection_main {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
}

.collection_detail,
.data_product_detail {
  background-color: use-color("base-lightest");
  border: $border;
  flex: 1;
  min-width: 600px;
  overflow-y: auto;
  padding: 10px;
  width: 100%;
}

.detail_header {
  background-color: use-color("white");
  border-bottom: 1px solid use-color("silver");
  min-width: 600px;
  padding: 1rem 1rem 0;
}

.detail_header h2 {
  margin-bottom: 0.5rem;
  margin-top: 0;
}

.context-tabs {
  margin-top: 0.5rem;
}

.match-highlight {
  background-color: use-color("warning-lightest");
}

.collection_toolbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.search-box {
  width: 400px;
}

.filter-controls {
  background-color: use-color("white");
  border-radius: 4px;
  margin-bottom: 0.5rem;
  padding: 0.5rem;
}

.filter-chips-label {
  color: use-color("base-dark");
}

.detail_content {
  background-color: use-color("base-lightest");
  display: flex;
  flex: 1;
  height: 100%;
  overflow-y: auto;
  width: 100%;
}

.dp_columns {
  display: flex;
  flex-flow: row;
  gap: 5px;
  max-width: 100%;
  overflow: hidden;

  >* {
    flex-basis: 1%;
    flex-grow: 1;
    flex-shrink: 0;
    overflow: auto;
  }
}

.grayscale {
  filter: grayscale(100%);
}

.sidebar-header h1 {
  font-size: 1.25rem;
  font-weight: normal;
  margin: 0;
  margin-bottom: 0.5rem;
  text-align: center;
}

.sidebar-image-wrapper {
  margin-bottom: 1rem;
  margin-top: 1rem;
  text-align: center;
}

.filters_panel {
  background-color: #fff;
  flex-shrink: 0;
  overflow: auto;
  width: 300px;
}

.filters-panel-header {
  align-items: center;
  box-shadow: 0 2px 5px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  justify-content: space-between;
  padding: 0.75rem;
  position: relative;
  z-index: 1;

  > h3 {
    color: use-color("base");
    font-size: 0.85rem;
    margin: 0;
    text-transform: uppercase;
  }
}

.filter-category {
  border-bottom: 1px solid use-color("base-lighter");
  transition-duration: 0.25s;

  /* stylelint-disable-next-line selector-class-pattern */
  &:global(.MuiAccordion-root):global(.MuiPaper-root) {
    border-radius: 0;
  }

  /* stylelint-disable-next-line selector-class-pattern */
  &:global(.MuiAccordion-root):global(.Mui-expanded) {
    margin: 0;
  }

  /* stylelint-disable-next-line selector-class-pattern */
  &:global(.MuiAccordion-root)::before {
    display: none;
  }

  /* stylelint-disable-next-line selector-class-pattern */
  :global(.MuiAccordionSummary-root) {
    transition: 0.25s;
  }

  /* stylelint-disable-next-line selector-class-pattern */
  :global(.MuiAccordionSummary-root):hover {
    background-color: use-color("primary-lightest");
  }
}

.filter-category-label {
  color: use-color("base-dark");
  font-weight: bold;
  margin: 0;
}

.filter-container:not(:last-child) {
  border-bottom: 1px dashed use-color("base-light");
  padding-bottom: 1rem;
}

.filter-label {
  transition: 0.25s;

  svg {
    margin-left: 0.5rem;
  }

  &.active {
    color: use-color("primary");
    cursor: pointer;
    font-weight: bold;
  }

  &.active:hover {
    opacity: 0.8;
  }
}

.table-toolbar {
  padding: 1rem;
}

.pagination-wrapper {
  padding: 1rem;
}

.match-modal,
.export-modal {
  width: 700px;
}

@media (max-width: 900px) {
  .match-modal,
  .export-modal {
    width: 80vw;
  }
}


.match-number-input {
  display: inline-block;
}

.range-filter {
  /* stylelint-disable-next-line selector-class-pattern */
  :global(.MuiInputBase-root) {
    font-size: 0.85rem;
  }
}

.slider-container {
  align-items: center;

  > * {
    max-width: 215px;
  }
}
